<template>
  <div>
    <!-- แถบแรก -->
    <b-card no-body>
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ข้อมูลโปรโมชั่น
          </h4>
          <hr />
          <div class="mt-3" />
          <form id="form_upload" method="POST">
            <b-row>
              <b-col cols="md-4">
                <b-form-group>
                  <label class="control-label">รูปภาพ</label>                  
                  <b-form-select ref="category" v-model="result.category" :options="option_category" />
                </b-form-group>
              </b-col>
              <b-col cols="md-4">
                <b-form-group>
                  <label class="control-label">ประเภท</label>
                  <b-form-select ref="type" v-model="result.type" :options="option_type" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="md-4">
                <b-form-group>
                  <label class="control-label">ชื่อโปรโมชั่น</label>
                  <input type="text" v-model="result.name" ref="name" class="form-control"  placeholder="ข้อมูลชื่อโปรโมชั่น">
                </b-form-group>
              </b-col>
              <b-col cols="md-4">
                <b-form-group v-if="result.category == 1">
                  <label class="control-label">จำนวนโบนัส (%)</label>
                  <input type="text" v-model="result.percent" ref="percent" class="form-control"  placeholder="ข้อมูลจำนวนโบนัส (%)">
                </b-form-group>
                <b-form-group v-else>
                  <label class="control-label">Fix ยอดฝาก (บาท)</label>
                  <input type="text" v-model="result.fix_amount_deposit" ref="fix_amount_deposit" class="form-control"  placeholder="Fix ยอดฝาก (บาท)">
                </b-form-group>
              </b-col>
              <b-col cols="md-4">
                <b-form-group v-if="result.category == 1">
                  <label class="control-label">โบนัสสูงสุด (บาท)</label>
                  <input type="text" v-model="result.max_value" ref="max_value" class="form-control"  placeholder="ข้อมูลโบนัสสูงสุด (บาท)">
                </b-form-group>
                <b-form-group v-else>
                  <label class="control-label">Fix โบนัส (บาท)</label>
                  <input type="text" v-model="result.fix_amount_deposit_bonus" ref="fix_amount_deposit_bonus" class="form-control"  placeholder="Fix โบนัส (บาท)">
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="md-4">
                <b-form-group>
                  <label class="control-label">คูณยอดเทิร์น</label>
                  <input type="text" v-model="result.turn" ref="turn" class="form-control"  placeholder="ข้อมูลคูณยอดเทิร์น">
                </b-form-group>
              </b-col>
              <b-col cols="md-4">
                <b-form-group>
                  <label class="control-label">ใช้ได้ต่อ User</label>
                  <input type="number" v-model="result.max_use" ref="max_use" class="form-control"  placeholder="ข้อมูลใช้ได้ต่อ User">
                </b-form-group>
              </b-col>
              <b-col cols="md-4">
                <b-form-group>
                  <label class="control-label">สถานะ</label>
                  <b-form-select ref="status" v-model="result.status" :options="option" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="md-12 mb-1">
                <label>รายละเอียด</label>
                <quill-editor
                  v-model="result.content"
                  :options="snowOption"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="md-3">
                <b-form-group>
                  <label>รูปภาพโลโก้ (1000x1000)</label>
                  <input type="file" name="image" ref="image" class="dropify" data-width="300" data-height="300" accept=".jpg,.jpeg,.png" data-allowed-file-extensions='["jpg", "jpeg", "png"]'  :data-title="result.image" :data-default-file="result.image_url" data-show-remove="false" />
                  <input type="text" v-model="result.image_url" ref="image_url" hidden>
                </b-form-group>
              </b-col>
              <b-col cols="md-9">
                <b-form-group>
                  <label>รูปภาพแบรนเนอร์ (700x200)</label>
                  <input type="file" name="image2" ref="image2" class="dropify" data-height="300" accept=".jpg,.jpeg,.png" data-allowed-file-extensions='["jpg", "jpeg", "png"]'  :data-title="result.image" :data-default-file="result.image_url2" data-show-remove="false" />
                  <input type="text" v-model="result.image_url2" ref="image_url2" hidden>
                </b-form-group>
              </b-col>
            </b-row>
          </form>
          <hr />
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <div class="text-right m-b-10">
                  <router-link :to="PATH" class="btn bg-gradient-warning waves-effect waves-light mr-1"><span><feather-icon icon="ArrowLeftIcon" class="font-medium-1 mr-1" /></span>ย้อนกลับ</router-link>
                  <button @click="update()" type="submit" class="btn bg-gradient-success waves-effect waves-light text-white"><span><feather-icon icon="SaveIcon" class="font-medium-1 mr-1" /></span>แก้ไข</button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <!-- สิ้นสุดท้าย -->
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'dropify/dist/js/dropify.min.js'
import 'dropify/dist/css/dropify.min.css'
import axios from 'axios'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BRow,
    BTable,
    BFormGroup,
    BFormSelect,
    vSelect,
    quillEditor,
  },
  data() {
    return {
      PATH: '/promotion',
      BASE_URL: process.env.BASE_URL,
      API_GET_URL: 'promotion/promotion_update/' + this.$route.params.id,
      API_UPDATE_URL: 'promotion/promotion_update/' + this.$route.params.id,
      snowOption: {
        theme: 'snow',
      },
      result: {
        content: '',
        category: 1,
        type: 1,
        name: '',
        percent: '',
        fix_amount_deposit: '',
        max_value: '',
        fix_amount_deposit_bonus: '',
        turn: '',
        max_use: 1,
        status: 1,
        image: '',
        image_url: '',
        image_url2: '',
      },
      option_category: [
        { text: 'ปกติ', value: '1' },
        { text: 'Fix ยอดฝาก/โบนัส', value: '2' },
      ],
      option_type: [
        { text: 'ถาวร', value: '1' },
        { text: 'รายวัน', value: '2' },
        { text: 'รายอาทิตย์', value: '3' },
        { text: 'รายเดือน', value: '4' },
        { text: 'สมาชิกใหม่', value: '5' },
      ],
      option: [
        { text: 'ปิด', value: '0' },
        { text: 'เปิด', value: '1' },
      ],
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    alertText(text='สำเร็จ!', type='success') {
      this.$swal({
        title: 'แจ้งเตือน',
        text: text,
        icon: type,
        confirmButtonText: 'เรียบร้อย',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    dropify() {
      var self = this
      this.drEvent = $(this.$el).find('.dropify').dropify({
        tpl: {
          wrap: '<div class="dropify-wrapper" style="border-radius: 5px;"></div>',
          loader: '<div class="dropify-loader"></div>',
          message: '<div class="dropify-message"><span class="file-icon"></span><br /> {{ default }}</div>',
          preview: '<div class="dropify-preview"><span class="dropify-render"></span><div class="dropify-infos"><div class="dropify-infos-inner"><p class="dropify-infos-message">{{ replace }}</p></div></div></div>',
          filename: '<p class="dropify-filename"><span class="file-icon"></span> <span class="dropify-filename-inner"></span></p>',
          clearButton: '<button type="button" class="dropify-clear">{{ remove }}</button>',
          errorLine: '<p class="dropify-error">ตรวจสอบชนิดไฟล์ให้ถูกต้อง</p>',
          errorsContainer: '<div class="dropify-errors-container"><ul></ul></div>'
        }
      });
      this.drEvent.on('dropify.fileReady', function(event, element) {
        if (element) {
          let target = event.target.attributes.name;
          const data = new FormData(document.getElementById('form_upload'))
          if (target.nodeValue == 'image2') {
            data.set('image', data.get('image2'))
          }
          axios.post('https://api.imgur.com/3/image', data, {
            headers: {
              Authorization: 'Client-ID 43905ab7492a794',
              Accept: 'application/json'
            }
          }).then(response=>{
            if (typeof response.data == 'string') {
              response.data = JSON.parse(response.data)
            }
            let res = response.data
            if (res.success) {
              if (target.nodeValue == 'image') {
                self.result.image_url = res.data.link
              } else {
                self.result.image_url2 = res.data.link
              }
              self.alertText('อัพโหลดรูปสำเร็จ', 'success')
            } else {
              self.alertText('อัพโหลดรูปภาพไม่สำเร็จ', 'error')
            }
          }).catch(error=>{
              self.alertText('ทำรายการไม่สำเร็จ '+error, 'warning')
          })
        }
      });
    },
    toMoney(value) {
      const parts = parseFloat(value).toFixed(2).split('.')
      return parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? `.${parts[1]}` : '')
    },
    loading() {
      this.$swal({
			  text: "กรุณารอสักครู่..",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: '',
        onBeforeOpen: () => {
          this.$swal.showLoading()
        },
      });
    },
    success() {
      this.$swal({
        title: 'แจ้งเตือน',
        text: 'แก้ไขสำเร็จแล้ว!',
        icon: 'success',
        confirmButtonText: 'เรียบร้อย',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    warn(text='') {
      this.$swal({
        title: 'แจ้งเตือน',
        text: text,
        icon: 'warning',
        confirmButtonText: 'ตกลง',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    fetch() {
      this.$http.get(this.API_GET_URL).then(res => {
        if (res.data != null && res.data.result != null) {
          this.result = res.data.result
          setTimeout(()=>this.dropify(), 1000/60)
        }
        else this.$router.push(this.PATH)
      }).catch(()=>this.$router.push(this.PATH))
    },
    update() {
      this.result.type = Number(this.result.type)
      this.result.status = Number(this.result.status)
      this.result.max_use = Number(this.result.max_use)
      this.result.category = Number(this.result.category)
      if (this.result.name.trim().length == '') {
        this.$refs.name.focus()
        return this.warn('กรุณาระบุชื่อโปรโมชั่น')
      }
      if (this.result.category == 1 && this.result.percent.length ==  0) {
        this.$refs.percent.focus()
        return this.warn('กรุณาระจำนวนโบนัส (%)')
      }
      if (this.result.category == 1 && this.result.max_value.length ==  0) {
        this.$refs.max_value.focus()
        return this.warn('กรุณาระบุเโบนัสสูงสุด (บาท)')
      }
      if (this.result.category == 2 && this.result.fix_amount_deposit.length ==  0) {
        this.$refs.fix_amount_deposit.focus()
        return this.warn('กรุณาระบุ Fix ยอดฝาก (บาท)')
      }
      if (this.result.category == 2 && this.result.fix_amount_deposit_bonus.length ==  0) {
        this.$refs.fix_amount_deposit_bonus.focus()
        return this.warn('กรุณาระบุ Fix โบนัส (บาท)')
      }
      if (this.result.turn.length ==  0) {
        this.$refs.turn.focus()
        return this.warn('กรุณาระบุเทิร์น')
      }
      if (this.result.max_use.length ==  0) {
        this.$refs.max_use.focus()
        return this.warn('กรุณาระบุใช้ได้ต่อ User')
      }
      if (this.result.image_url.trim().length == '') {
        this.$refs.image_url.focus()
        return this.warn('กรุณาระบุภาพโลโก้')
      }
      if (this.result.image_url2 != null && this.result.image_url2.trim().length == '') {
        this.$refs.image_url2.focus()
        return this.warn('กรุณาระบุภาพแบรนเนอร์')
      }
      this.loading()
      this.$http.post(this.API_UPDATE_URL, this.result).then(res=> {
        this.$swal.close()
        this.$router.push(this.PATH)
      }).catch(()=>this.$router.push(this.PATH))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
body.dark-layout .dropify-wrapper:hover { background-image: -webkit-linear-gradient(-45deg,#283046 25%,transparent 25%,transparent 50%,#283046 50%,#283046 75%,transparent 75%,transparent); background-image: linear-gradient(-45deg,#283046 25%,transparent 25%,transparent 50%,#283046 50%,#283046 75%,transparent 75%,transparent);}
body.dark-layout .dropify-wrapper { background-color: #161d30; }
</style>
